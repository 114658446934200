.steps {
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 15px;
	max-width: 950px;
	margin-bottom: 20px;
	margin-top: 30px;
	width: 100%;
}

.page-title {
	font-size: 36px;
	margin-bottom: 30px;
}

.steps .progress-line {
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: var(--gray7575);
}

.steps li {
	font-size: 17px;
	color: var(--textColor);
	text-align: center;
	line-height: 1.2;
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.steps li span.step-icon {
	display: flex;
	width: 35px;
	height: 35px;
	background-color: transparent;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	position: relative;
	z-index: 1;
	margin-right: 20px;
	border: 1px solid var(--btn-green-bg);
}

.steps li span.step-icon i {
	color: var(--white);
	font-size: 24px;
}

.steps li.active .step-icon {
	background-color: var(--white);
	color: var(--black);
}

.steps li.active .step-icon i {
	color: red;
	font-weight: var(--fontWeight700);
}

.steps li.active::after {
	background-color: var(--theme-color-one);
}

.steps-content-wrapper {
	border-radius: 12px;
	padding: 25px;
	color: var(--textColor);
	flex-flow: column;
	width: 60%;
	background-color: var(--primaryColor3);
	position: relative;
}

.single-step-content {
	display: none;
}

.single-step-content.show {
	display: block;
}

.form-check-label {
	font-size: 17px;
	cursor: pointer;
	margin-top: -2px;
	margin-left: 3px;
	line-height: 1.3;
}

.form-check-label span {
	font-size: 16px;
}

.bdy-part .form-check {
	border-radius: 12px;
	padding: 10px;
	padding-left: 40px;
	margin-bottom: 10px;
	transition: .3s;
	cursor: pointer;
	padding-bottom: 30px;
}

.form-check.shadow-none {
	padding-bottom: 0px;
	padding-top: 0px;
}

.head-part h3 {
	font-size: 28px;
	margin-bottom: 15px;
	letter-spacing: 1px;
}

.head-part p {
	font-size: 18px;
	line-height: 1.2;
}

.head-part {
	margin-bottom: 30px;
}

.form-check-input {
	border: 1px solid var(--textColor);
	transition: .3s;
}

.form-check-input:checked {
	background-color: var(--CheckIcBg);
	padding: 15px;
	border-radius: 50%;
	margin-right: 12px;
}

.form-check-input:focus {
	box-shadow: none;
	border-color: var(--theme-color-one);
}

.terms-condition {
	text-decoration: underline;
	color: var(--black);
	transition: .3s;
	display: inline;
}

.terms-condition:hover {
	color: var(--theme-color-one);
	text-decoration: underline;
}

.bsBtn-info.nav-btn {
	color: var(--white);
	background-color: var(--btn-green-bg);
	font-size: 16px;
	border: 1px solid var(--black);
	width: 150px;
	padding: 6px 15px;
	transition: .3s;
	border-radius: 8px;
	color: var(--black);
}

.bsBtn-info.nav-btn:hover {
	background-color: var(--btn-green-bg);
	border-color: var(--theme-color-one);
}

.step-next-prev-btn button {
	margin-left: 10px;
}

.step-next-prev-btn {
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

#next.nav-btn {
	background-color: var(--theme-color-one);
	border-color: var(--theme-color-one);
}

#next.nav-btn:hover {
	background-color: transparent;
}

.stack-balance-wrapper p {
	font-size: 18px;
}

.stack-balance-wrapper p span {
	color: var(--textColor);
	display: inline-block;
}

.percentage-btn button {
	border: 1px solid var(--theme-color-one);
	background-color: rgba(26, 220, 171, 0.1);
	color: var(--btn-green-bg) !important;
	border-radius: 15px;
	width: 60px;
	height: 60px;
	margin-right: 10px;
	line-height: 40px;
	font-size: 16px;
	color: var(--black);
	transition: .3s;
}

.percentage-btn {
	display: flex;
	align-items: center;
}

.percentage-btn button:hover {
	color: var(--theme-color-one);
}

.step-content-box {
	min-height: 300px;
}

.auth-content p {
	font-size: 18px;
	margin-bottom: 10px;
}

.auth-content p {
	font-size: 18px;
	margin-bottom: 10px;
}

.success-step-content {
	text-align: center;
}

.sccs i {
	color: var(--btn-green-bg);
	display: block;
	font-size: 45px;
}

.sccs {
	color: var(--white);
	font-size: 28px;
	margin-bottom: 10px;
}

.success-step-content p {
	font-size: 18px;
	margin-bottom: 10px;
}

.hash-tag {
	color: var(--theme-color-one);
}

.dashboard-main .card .card-body h2 {
	font-weight: var(--fontWeight400);
	margin: 0;
	line-height: 50px;
	font-size: 30px;
}

.dashboard-main .card .card-body i {
	background-color: var(--btn-green-bg);
	padding: 0;
	border-radius: 50%;
	font-size: 1.5rem;
	float: right;
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 40px;
}

.dashboard-main .card .card-body h1 {
	font-size: 36px;
	color: var(--btn-green-bg);
	margin-top: 50px;
}

/* Media Query  */
@media screen and (max-width: 767px) {
	.steps li span.step-icon {
		width: 40px;
		height: 40px;
	}

	.steps li span.step-icon i {
		font-size: 17px;
	}

	.steps li {
		font-size: 12px;
	}

	.steps {
		grid-gap: 5px;
	}

	.steps li:not(:first-child)::after {
		top: 21px;
	}

	.steps-content-wrapper {
		padding: 20px;
	}

	.head-part h3 {
		font-size: 24px;
	}

	.form-check-label {
		font-size: 16px;
	}

	.stack-balance-wrapper {
		display: block !important;
	}

	.percentage-btn button {
		width: 90px;
		height: 34px;
		line-height: 33px;
		font-size: 14px;
		margin-bottom: 10px;
	}

	.stack-balance-wrapper p {
		font-size: 16px;
	}

	.auth-content p {
		font-size: 16px;
	}

	.step-content-box {
		min-height: 230px;
	}
}

.dashboad-main .card .card-body {
	position: relative;
	border-radius: 15px;
}

.ant-btn-primary {
	width: 59%;
	height: 3rem;
	font-size: 16px;
	background-color: var(--btn-green-bg);
	border-radius: 10px;
	border: none;
	color: var(--black);
	font-weight: var(--fontWeight400);
}

.token-wrapper {
	display: grid;
	grid-gap: 30px;
	background-color: var(--white-bg);
	padding: 30px;
	position: relative;
	height: 100%;
}

/* --------New sui stake css----- */
.staking-stats-wrapper {
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}

.bar-ic {
	position: absolute;
	z-index: 1;
	right: 0px;
	width: 19%;
	top: -15%;
}